import { useContext, createContext } from "react";
import CommonStore from "./commonStore";
import HdwStore from "./hdwStore";
import ModalStore from "./modalStore";
import UserStore from "./userStore";

interface Store {
    hdwStore: HdwStore;
    commonStore: CommonStore;
    userStore: UserStore;
    modalStore: ModalStore;
    // profileStore: ProfileStore;
    // commentStore: CommentStore;
}

export const store: Store = {
    hdwStore: new HdwStore(),
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    modalStore: new ModalStore(),
    // profileStore: new ProfileStore(),
    // commentStore: new CommentStore()
}


export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}