import { Card, Col, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import Link from "antd/lib/typography/Link";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from '../../../app/stores/store';
import HdwFilters from "./HdwFilter";
import HdwList from "./HdwList";


export default observer(function HdwDashboard() {

    const { hdwStore, userStore, modalStore } = useStore();
    const { loadHdwRequests, hdwRegistry } = hdwStore;
    const [activeTabKey2, setActiveTabKey2] = useState<string>('hdw');

    useEffect(() => {
        // if (hdwRegistry.size <= 1) loadHdwRequests();
        loadHdwRequests();
    }, [hdwRegistry, loadHdwRequests])

    // useEffect(() => {
    //     // if (hdwRegistry.size <= 1) loadHdwRequests();
    //     var testUser = userStore.getUser();

    // }, [])

    // useEffect(() => {
    //     // if (hdwRegistry.size <= 1) loadHdwRequests();
    //     loadHdwRequests();
    // })


    if (hdwStore.loadingInitial) return <LoadingComponent content='Loading requests...' />


    const tabListNoTitle = [
        {
            key: 'hdw',
            tab: 'hdw',
        },
        {
            key: 'app',
            tab: 'app',
        },
        {
            key: 'project',
            tab: 'project',
        },
    ];

    const contentListNoTitle: Record<string, React.ReactNode> = {
        article: <p>You want to estimate the performance of your formulation before doing all the lab work? <a href="/hdwAiRequest" style={{ textDecoration: 'underline' }}>Click here!</a></p>,
        app: <p>app content</p>,
        project: <p>project content</p>,
    };
    const onTab2Change = (key: string) => {
        setActiveTabKey2(key);
    };

    return (
        <>
            {userStore.isAccessItAdmin || userStore.isEmUser || userStore.isEmReadOnly ? (<Col span={8}>
                <Link href="/hdwAiRequest">
                    <Card
                        hoverable
                        style={{ width: 'inherit' }}
                        cover={<img alt="example" src='/assets/categoryImages/em.png' />}
                    >
                        <Meta title="Home Care and Industrial & Institutional Cleaning Solutions" description="www.basf.com" />
                    </Card>
                </Link>
            </Col>) : (<Col span={8}>
                <Card
                    hoverable
                    style={{ width: 'inherit', opacity: 0.2}}
                    cover={<img alt="example" src='/assets/categoryImages/em.png' />}

                >
                    <Meta title="Disabled" description="Disabled" />
                </Card>
            </Col>)}
            <Row gutter={[32, 32]}>
                {/* <Col span={8}>
                    <Link href="/hdwAiRequest">
                        <Card
                            hoverable
                            style={{ width: 'inherit' }}
                            cover={<img alt="example" src='/assets/categoryImages/ap.png' />}
                        >
                            <Meta title="Seed Solutions" description="www.basf.com" />
                        </Card>
                    </Link>
                </Col> */}
                {/* <Col span={8}>
                    <Link href="/hdwAiRequest">
                        <Card
                            hoverable
                            style={{ width: 'inherit' }}
                            cover={<img alt="example" src='/assets/categoryImages/em.png' />}
                        >
                            <Meta title="Home Care and Industrial & Institutional Cleaning Solutions" description="www.basf.com" />
                        </Card>
                    </Link>
                </Col> */}
                {/* <Col span={8}>
                    <Link href="/hdwAiRequest">
                        <Card
                            hoverable
                            style={{ width: 'inherit' }}
                            cover={<img alt="example" src='/assets/categoryImages/personalcare.png' />}
                        >
                            <Meta title="Personal Care" description="www.basf.com" />

                        </Card>
                    </Link>
                </Col>
                <Col span={8}>
                    <Link href="/hdwAiRequest">
                        <Card
                            hoverable
                            style={{ width: 'inherit' }}
                            cover={<img alt="example" src='/assets/categoryImages/ed.png' />}
                        >
                            <Meta title="Architectural and Industrial Coatings" description="www.basf.com" />

                        </Card>
                    </Link>
                </Col>
                <Col span={8}>
                    <Link href="/hdwAiRequest">
                        <Card
                            hoverable
                            style={{ width: 'inherit' }}
                            cover={<img alt="example" src='/assets/categoryImages/ec.png' />}
                        >
                            <Meta title="Refinish Competence Centre" description="www.basf.com" />

                        </Card>
                    </Link>
                </Col>
                <Col span={8}>
                    <Link href="/hdwAiRequest">
                        <Card
                            hoverable
                            style={{ width: 'inherit' }}
                            cover={<img alt="example" src='/assets/categoryImages/en.png' />}
                        >
                            <Meta title="Food Solutions" description="www.basf.com" />

                        </Card>
                    </Link>
                </Col>
                <Col span={8}>
                    <Link href="/hdwAiRequest">
                        <Card
                            hoverable
                            style={{ width: 'inherit' }}
                            cover={<img alt="example" src='/assets/categoryImages/pm.png' />}
                        >
                            <Meta title="Performance Materials" description="www.basf.com" />

                        </Card>
                    </Link>
                </Col> */}

            </Row>


            {/* <Grid>
                <Grid.Column width='10'>
                    <HdwList />
                </Grid.Column>
                <Grid.Column width='6'>
                    <HdwFilters />
                </Grid.Column>
            </Grid> */}
        </>
    )
})