import { observer } from 'mobx-react-lite';
import React from 'react'
import { Segment, Grid, Icon, Button } from 'semantic-ui-react'
import { Hdw } from "../../../app/models/hdw";

interface Props {
    hdw: Hdw
}

export default observer(function HdwDetailedInfo({ hdw }: Props) {
    return (
        <Segment.Group>
            <Segment attached='top'>
                <Grid>
                    <Grid.Column width={1}>
                        <Icon size='large' color='teal' name='trophy' />
                    </Grid.Column>
                    <Grid.Column width={15}>
                        <p><strong>platesIKW - {hdw.platesIKW}</strong></p>
                    </Grid.Column>
                </Grid>
            </Segment>
            <Segment attached>
                <Grid verticalAlign='middle'>
                    <Grid.Column width={1}>
                        <Icon name='tint' size='large' color='teal' />
                    </Grid.Column>
                    <Grid.Column width={15}>
                        <p>dehytonPK45 - <strong>{hdw.dehytonPK45}</strong></p>
                    </Grid.Column>
                </Grid>
            </Segment>
            <Segment attached>
                <Grid verticalAlign='middle'>
                    <Grid.Column width={1}>
                        <Icon name='tint' size='large' color='teal' />
                    </Grid.Column>
                    <Grid.Column width={15}>
                        <p>dehytonPL - <strong>{hdw.dehytonPL}</strong></p>
                    </Grid.Column>
                </Grid>
            </Segment>
            <Segment attached>
                <Grid verticalAlign='middle'>
                    <Grid.Column width={1}>
                        <Icon name='tint' size='large' color='teal' />
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <p>glucopon600CSUP - <strong>{hdw.glucopon600CSUP}</strong></p>
                    </Grid.Column>
                </Grid>
            </Segment>
            <Segment attached>
                <Grid verticalAlign='middle'>
                    <Grid.Column width={1}>
                        <Icon name='tint' size='large' color='teal' />
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <p>lutensolXP80 - <strong>{hdw.lutensolXP80}</strong></p>
                    </Grid.Column>
                </Grid>
            </Segment>
            <Segment attached>
                <Grid verticalAlign='middle'>
                    <Grid.Column width={1}>
                        <Icon name='tint' size='large' color='teal' />
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <p>maranilPasteA55 - <strong>{hdw.maranilPasteA55}</strong></p>
                    </Grid.Column>
                </Grid>
            </Segment>
            <Segment attached>
                <Grid verticalAlign='middle'>
                    <Grid.Column width={1}>
                        <Icon name='tint' size='large' color='teal' />
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <p>sokalanHP20 - <strong>{hdw.sokalanHP20}</strong></p>
                    </Grid.Column>
                </Grid>
            </Segment>
            <Segment attached>
                <Grid verticalAlign='middle'>
                    <Grid.Column width={1}>
                        <Icon name='tint' size='large' color='teal' />
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <p>sulfopon101 - <strong>{hdw.sulfopon101}</strong></p>
                    </Grid.Column>
                </Grid>
            </Segment>
            <Segment attached>
                <Grid verticalAlign='middle'>
                    <Grid.Column width={1}>
                        <Icon name='tint' size='large' color='teal' />
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <p>texaponN70 - <strong>{hdw.texaponN70}</strong></p>
                    </Grid.Column>
                </Grid>
            </Segment>
        </Segment.Group>
    )
})