import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Button, Header, Item, Segment, Icon, Image } from 'semantic-ui-react'
import { Hdw } from "../../../app/models/hdw";
import { useStore } from '../../../app/stores/store';

const hdwImageStyle = {
    filter: 'brightness(50%)'
};

const hdwImageTextStyle = {
    position: 'absolute',
    bottom: '5%',
    left: '5%',
    width: '100%',
    height: 'auto',
    color: 'white'
};

interface Props {
    hdw: Hdw
}

export default observer(function HdwDetailedHeader({ hdw }: Props) {
    const { hdwStore: { updateSubscription, loading, updateFavorite } } = useStore();


    return (
        <Segment.Group>
            <Segment basic attached='top' style={{ padding: '0' }}>
                {/* {hdw.isFavorite &&
                    <Label style={{ position: 'absolute', zIndex: 1000, left: -14, top: 20 }}
                        ribbon color='green' content='Added to favorites' />
                } */}
                <Image src={`/assets/categoryImages/white_plate.png`} fluid style={hdwImageStyle} /> 
                <Segment style={hdwImageTextStyle} basic>
                    <Item.Group>
                        <Item>
                            <Item.Content>
                                <Header
                                    size='huge'
                                    content={hdw.platesIKW}
                                    style={{ color: 'white' }}
                                />
                                <p>{hdw.createDate}</p>
                                <p>
                                    Owned by <strong><Link to={`/profiles/${hdw.owner?.username}`}>{hdw.owner?.displayName}</Link></strong>
                                </p>
                            </Item.Content>
                        </Item>
                    </Item.Group>
                </Segment>
            </Segment>
            <Segment clearing attached='bottom'>
                {hdw.isOwner ? (
                    <>
                        {/* <Button animated='vertical' style={{ backgroundColor: 'white', borderRadius: 0 }}>
                            <Button.Content visible={hdw.isFavorite} hidden={!hdw.isFavorite} >
                                <Icon
                                    name='favorite'
                                    color='orange'
                                    onClick={() => updateFavorite(hdw)}
                                    loading={loading}
                                />
                            </Button.Content>
                            <Button.Content hidden={hdw.isFavorite} visible={!hdw.isFavorite}>
                                <Icon
                                    name='favorite'
                                    color='grey'
                                    onClick={() => updateFavorite(hdw)}
                                    loading={loading}
                                />
                            </Button.Content>
                        </Button> */}
                        <Button
                            color={hdw.isFavorite ? 'red' : 'green'}
                            floated='left'
                            content={hdw.isFavorite ? 'Remove favorite' : 'Add favorite'}
                            onClick={() => updateFavorite(hdw)}
                            loading={loading}
                            style={{ borderRadius: 0 }}
                            circular
                        />
                        <Button as={Link}
                            disabled={!hdw.isFavorite}
                            to={`/manage/${hdw.id}`}
                            color='orange'
                            floated='right'>
                            Manage Event
                        </Button>
                    </>
                ) : hdw.isSubscriber ? (
                    <Button loading={loading} onClick={updateSubscription}>Cancel attendance</Button>
                ) : (
                    <Button disabled={hdw.isFavorite}
                        loading={loading} onClick={updateSubscription} color='teal'>
                        Join Hdw
                    </Button>
                )}
            </Segment>
        </Segment.Group>
    )
})