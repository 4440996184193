import HdwDashboard from '../../features/hdwRequests/dashboard/HdwDashboard';
import { observer } from 'mobx-react-lite';
import { Route, Switch, useLocation, HashRouter, Link } from 'react-router-dom';
import HomePage from '../../features/home/HomePage';
import HdwDetails from '../../features/hdwRequests/details/HdwDetails';
import LoginForm from '../../features/users/LoginForm';
import { useStore } from '../stores/store';
import { useEffect } from 'react';
import LoadingComponent from './LoadingComponent';
import ModalContainer from '../common/modals/ModalContainer';
import { ToastContainer } from 'react-toastify';
import TestErrors from '../../features/errors/TestError';
import ServerError from '../../features/errors/ServerError';
import NotFound from '../../features/errors/NotFound';
import HdwTestForm from '../../features/hdwRequests/form/HdwTestForm';
import Sider from './Sider';
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
// import Breadcrumbs from './Breadcrumbs';
import React from 'react';
import { Alert, Breadcrumb } from 'antd';
import SamlResponse from '../../features/hdwRequests/dashboard/SamlResponse';
import { history } from '../..';

function App() {
  const location = useLocation();
  const { commonStore, userStore } = useStore();

  useEffect(() => {
    if (commonStore.token) {
      userStore.getUser().finally(() => commonStore.setAppLoaded());
    } else {
      commonStore.setAppLoaded();
    }
  }, [commonStore, userStore])

  if (!commonStore.appLoaded) return <LoadingComponent content='Loading app' />

  const breadcrumbNameMap: Record<string, string> = {
    '/hdwRequests': 'Hdw Requests',
    '/hdwAiRequest': 'Hdw Ai Request',
  };

  const pathSnippets = location.pathname.split('/').filter((i) => i);

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap[url]}</Link>
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <>

      <Layout style={{ minHeight: '100vh' }}>
        <ToastContainer position='bottom-right' hideProgressBar />
        <ModalContainer />
        <Route exact path='/' component={HomePage} />
        <Route
          path={'/(.+)'}
          render={() => (
            <>

              <Sider />
              <Content style={{ margin: '0 16px' }}>
                {/* <Breadcrumbs/> */}
                <Breadcrumb>{breadcrumbItems}</Breadcrumb>

                <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                  <Switch>
                    <Route exact path='/samlresponse' component={SamlResponse} />
                    <Route exact path='/hdwRequests' component={HdwDashboard} />
                    <Route path='/hdwRequests/:id' component={HdwDetails} />
                    {/* {userStore.isAccessItAdmin ?(<Route path='/hdwRequests/:id' component={HdwDetails} />):( history.push('/')) } */}
                    {/* <Route key={location.key} path={['/createHdw', '/manage/:id']} component={HdwForm} /> */}
                    {/* <Route key={location.key} path={'/hdwAiRequest'} component={HdwForm} /> */}
                    <Route exact path={'/hdwAiRequest'} component={HdwTestForm} />
                    <Route key={location.key} path={'/hdwAiRequest/:id'} component={HdwTestForm} />
                    <Route path='/errors' component={TestErrors} />
                    <Route path='/server-error' component={ServerError} />
                    <Route path='/login' component={LoginForm} />
                    <Route component={NotFound} />
                  </Switch>
                </div>
              </Content>
            </>
          )}
        />
      </Layout>
    </>
  );
}

export default observer(App);
