import { FormInstance, message, Popconfirm, Space } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { observer } from "mobx-react-lite";
import { Hdw, HdwFormValues } from "../../../app/models/hdw";
import { useStore } from "../../../app/stores/store";
import { EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

interface Props {
    setHdw: any
    setViewState: any
    form: FormInstance
    hdw: any
    selectedHdw: Hdw
}

export default observer(function HdwFormList({ setViewState, form, setHdw, hdw, selectedHdw }: Props) {
    const history = useHistory();
    const { hdwStore } = useStore();
    const { hdwRequestsByDate, updateFavorite } = hdwStore;

    const cancel = (e: React.MouseEvent<HTMLElement>) => {
        console.log(e);
        message.error('Click on No');
    };

    const columns: ColumnsType<Hdw> = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: function (a, b) {

                if (a.name === null)
                    a.name = ''

                if (b.name === null)
                    b.name = ''

                const nameA = a.name!.toUpperCase(); // ignore upper and lowercase
                const nameB = b.name!.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            },
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Create Date',
            dataIndex: 'createDate',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => new Date(b.createDate).getTime() - new Date(a.createDate!).getTime(),
            //render: (value) => new Date(value).toUTCString()
            render: (value) => new Date(value).toLocaleString()
        },
        {
            title: 'Plates IKW',
            dataIndex: 'platesIKW',
            sorter: (a, b) => a.platesIKW - b.platesIKW,
            render: (value) => value.toFixed(2),
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (_, record) => (
                <Space size="middle" key={record.id}>
                    {/* <EyeOutlined onClick={() => { form.setFieldsValue(record); setViewState(true); }} /> */}
                    <EyeOutlined onClick={() => { setHdw(record); setViewState(true); }} />
                    <EditOutlined onClick={() => history.push(`/hdwAiRequest/${record.id}`)} />
                    <Popconfirm
                        title="Are you sure to delete this modal?"
                        onConfirm={() => updateFavorite(record).then(() => setHdw(new HdwFormValues()))}
                        onCancel={() => cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <>
            <Table rowClassName={(record) => (selectedHdw === undefined ? "transparent" : (record.id === selectedHdw.id ? "table-row-light" : "table-row-dark"))} columns={columns} dataSource={hdwRequestsByDate} />
        </>
    )
})