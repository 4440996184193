import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Hdw, HdwFormValues } from "../models/hdw";
import { Profile } from "../models/profile";
import { store } from "./store";

export default class HdwStore {
    hdwRegistry = new Map<string, Hdw>();
    selectedHdw: Hdw | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;

    constructor() {
        makeAutoObservable(this)
    }

    //order by date
    get hdwRequestsByDate() {
        return Array.from(this.hdwRegistry.values()).sort((a, b) => new Date(b.createDate).getTime() - new Date(a.createDate!).getTime());
        //return Array.from(this.hdwRegistry.values()).sort((a, b) => b.createDate.getTime() - a.createDate.getTime());
        //return Array.from(this.hdwRegistry.values()).sort((a, b) => Date.parse(a.createDate) - Date.parse(b.createDate));
    }

    get groupedHdwRequests() {
        return Object.entries(
            this.hdwRequestsByDate.reduce((hdwRequests, hdw) => {
                const date = hdw.createDate!.toString().split("T")[0];
                hdwRequests[date] = hdwRequests[date] ? [...hdwRequests[date], hdw] : [hdw];
                return hdwRequests;
            }, {} as { [key: string]: Hdw[] })
        )
    }

    loadHdwRequests = async () => {
        this.loadingInitial = true;
        try {
            this.hdwRegistry.clear();
            const hdwRequests = await agent.HdwRequests.list();
            hdwRequests.forEach(hdw => {
                this.setHdw(hdw);
            })
            this.setLoadingInitial(false);
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    loadHdwRequest = async (id: string) => {
        let hdw = this.getHdw(id);
        if (hdw) {
            this.selectedHdw = hdw;
            return hdw;
        } else {
            this.loadingInitial = true;
            try {
                hdw = await agent.HdwRequests.details(id);
                this.setHdw(hdw);
                runInAction(() => {
                    this.selectedHdw = hdw;
                })
                this.setLoadingInitial(false);
                // return hdw;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setHdw = (hdw: Hdw) => {
        const user = store.userStore.user;
        if (user) {
            hdw.isSubscriber = hdw.subscribers!.some(
                a => a.username === user.username
            )
            hdw.isOwner = hdw.ownerUsername === user.username;
            hdw.owner = hdw.subscribers?.find(x => x.username === hdw.ownerUsername);
        }
        this.hdwRegistry.set(hdw.id, hdw);
    }

    private getHdw = (id: string) => {
        return this.hdwRegistry.get(id);
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    createHdw = async (hdw: HdwFormValues) => {
        const user = store.userStore.user;
        const subscriber = new Profile(user!);
        try {
            // hdw.createDate = new Date(Date.now());
            await agent.HdwRequests.create(hdw);
            const newHdw = new Hdw(hdw);
            newHdw.ownerUsername = user!.username;
            newHdw.subscribers = [subscriber];
            //this.setHdw(newHdw);
            // runInAction(() => {
            //     //this.loadHdwRequest(newHdw.id);
            //     //this.selectedHdw = newHdw;
            // })
        } catch (error) {
            console.log(error);
        }
    }

    sendHdwAiRequest = async (hdw: HdwFormValues) => {
        this.loading = true;
        const user = store.userStore.user;
        const subscriber = new Profile(user!);
        try {
            hdw.createDate = new Date(Date.now());
            var newHdw = await agent.HdwRequests.hdwAiRequest(hdw);
            newHdw.ownerUsername = user!.username;
            newHdw.subscribers = [subscriber];
            // this.setHdw(newHdw);
            return newHdw;
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => this.loading = false);
        }
    }

    updateHdw = async (hdw: HdwFormValues) => {
        try {
            await agent.HdwRequests.update(hdw);
            runInAction(() => {
                if (hdw.id) {
                    let updatedHdw = { ...this.getHdw(hdw.id), ...hdw }
                    this.hdwRegistry.set(hdw.id, updatedHdw as Hdw);
                    this.selectedHdw = updatedHdw as Hdw;
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    deleteHdw = async (id: string) => {
        this.loading = true;
        try {
            await agent.HdwRequests.delete(id);
            runInAction(() => {
                this.hdwRegistry.delete(id);
                // if (this.selectedHdw?.id === id) this.cancelSelectedHdw();
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
        }
    }

    updateSubscription = async () => {
        const user = store.userStore.user;
        this.loading = true;
        try {
            await agent.HdwRequests.subscribe(this.selectedHdw!.id);
            runInAction(() => {
                if (this.selectedHdw?.isSubscriber) {
                    this.selectedHdw.subscribers =
                        this.selectedHdw.subscribers?.filter(a => a.username !== user?.username);
                    this.selectedHdw.isSubscriber = false;
                } else {
                    const subscriber = new Profile(user!);
                    this.selectedHdw?.subscribers?.push(subscriber);
                    this.selectedHdw!.isSubscriber = true;
                }
                this.hdwRegistry.set(this.selectedHdw!.id, this.selectedHdw!)
            })
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => this.loading = false);
        }
    }

    updateFavorite = async (hdw: Hdw) => {
        this.loading = true;
        try {
            runInAction(() => {
                hdw!.isFavorite = !hdw?.isFavorite;
                this.hdwRegistry.set(hdw!.id, hdw!);
            })
            if (hdw?.isFavorite) {
                this.createHdw(hdw);
            } else {
                this.deleteHdw(hdw.id);
            }
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => this.loading = false);
        }
    }
    // updateFavorite = async (hdw: Hdw) => {
    //     this.loading = true;
    //     this.selectedHdw = hdw;
    //     try {
    //         runInAction(() => {
    //             this.selectedHdw!.isFavorite = !this.selectedHdw?.isFavorite;
    //             this.hdwRegistry.set(this.selectedHdw!.id, this.selectedHdw!);
    //         })
    //         if (this.selectedHdw?.isFavorite) {
    //             this.createHdw(this.selectedHdw);
    //         } else {
    //             this.deleteHdw(this.selectedHdw.id);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     } finally {
    //         runInAction(() => this.loading = false);
    //     }
    // }
}