import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { Hdw, HdwFormValues } from "../../../app/models/hdw";
import { useStore } from "../../../app/stores/store";
import { v4 as uuid } from "uuid";
import { Pie } from '@ant-design/plots';
import { Button, Card, Col, Form, InputNumber, Row, Tag } from "antd";
import MyTextInputAnt from "../../../app/common/form/MyTextInputAnt";
import HdwFormResults from "./HdwFormResults";
import HdwFormList from "./HdwFormList";
import HdwFormModal from "./HdwFormModal";
import { ExclamationCircleOutlined } from '@ant-design/icons';

export default observer(function HdwTestForm() {
    const { hdwStore,userStore } = useStore();
    const { selectedHdw: selectedHdw, loadHdwRequest, loadingInitial, sendHdwAiRequest, loadHdwRequests, hdwRegistry, loading } = hdwStore;
    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    const [hdw, setHdw] = useState<Hdw>();
    const [form] = Form.useForm(undefined);
    const [isView, setIsView] = useState<boolean>();
    const [isNewPage, setIsNewPage] = useState<boolean>(true);
    const [sendButtonDisable, setSendButtonDisable] = useState<boolean>(true);

    const [activeMatterAttentionVisible, setactiveMatterAttentionVisible] = useState(false);

    // const [testdeger, settestdeger] = useState(1);
    // const [kalanDeger, setKalanDeger] = useState(1);
    // const [totalValue, setTotalValue] = useState(0);
    // const [isMaxed, setIsMaxed] = useState<boolean>(false);
    // const [totalValue, setTotalValue] = useState(0);
    const [totalActiveMatter, setTotalActiveMatter] = useState(0);
    const [testNumber, setTestNumber] = useState(0);

    useEffect(() => {
        if (totalActiveMatter > 0.11 && totalActiveMatter < 0.17) {
            setactiveMatterAttentionVisible(true)
        } else {
            setactiveMatterAttentionVisible(false)
        }
        // console.log(totalActiveMatter)
    }, [totalActiveMatter]);

    useEffect(() => {
        form.setFieldsValue(hdw);
    }, [hdw, form]);

    useEffect(() => {
        if (id) setIsView(false);
    }, [id]);

    useEffect(() => {
        if (userStore.isAccessItAdmin || userStore.isEmUser || userStore.isEmReadOnly)
        { 

        } else {
            history.push('/hdwRequests');
        }    
    }, []);

    useEffect(() => {
        if (id) loadHdwRequest(id);
        setHdw(selectedHdw);
    }, [id, loadHdwRequest]);

    useEffect(() => {
        setHdw(selectedHdw);
    }, [selectedHdw])

    useEffect(() => {
        loadHdwRequests();
    }, [hdwRegistry, loadHdwRequests]);

    useEffect(() => {
        if (totalActiveMatter === 0 || isView) {
            setSendButtonDisable(true);
        } else {
            setSendButtonDisable(false);
        }
    }, [totalActiveMatter]);




    async function handleFormSubmit(hdw: HdwFormValues) {
        let newHdw = {
            ...hdw,
            id: uuid()
        };
        setHdw(await sendHdwAiRequest(newHdw));
    }

    let data = [
        {
            type: 'glucopon600CSUP',
            value: Form.useWatch('glucopon600CSUP', form),
            activeMatterValue: Form.useWatch('glucopon600CSUP', form) * 0.51,

        },
        {
            type: 'maranilPasteA55',
            value: Form.useWatch('maranilPasteA55', form),
            activeMatterValue: Form.useWatch('maranilPasteA55', form) * 0.55,
        },
        {
            type: 'texaponN70',
            value: Form.useWatch('texaponN70', form),
            activeMatterValue: Form.useWatch('texaponN70', form) * 0.7,
        },
        {
            type: 'dehytonPK45',
            value: Form.useWatch('dehytonPK45', form),
            activeMatterValue: Form.useWatch('dehytonPK45', form) * 0.45,
        },
        {
            type: 'dehytonPL',
            value: Form.useWatch('dehytonPL', form),
            activeMatterValue: Form.useWatch('dehytonPL', form) * 0.3,
        },
        {
            type: 'sokalanHP20',
            value: Form.useWatch('sokalanHP20', form),
            activeMatterValue: Form.useWatch('sokalanHP20', form) * 0.8,
        },
        {
            type: 'sulfopon101',
            value: Form.useWatch('sulfopon101', form),
            activeMatterValue: Form.useWatch('sulfopon101', form) * 0.3,
        },
        {
            type: 'lutensolXP80',
            value: Form.useWatch('lutensolXP80', form),
            activeMatterValue: Form.useWatch('lutensolXP80', form) * 1,
        },
    ];

    let activeMatters = [
        {
            name: 'maranilPasteA55ActiveMatter',
            value: 0.55,
        },
        {
            name: 'texaponN70ActiveMatter',
            value: 0.7,
        },
        {
            name: 'dehytonPK45ActiveMatter',
            value: 0.45,
        },
        {
            name: 'dehytonPLActiveMatter',
            value: 0.3,
        },
        {
            name: 'glucopon600CSUPActiveMatter',
            value: 0.51,
        },
        {
            name: 'sokalanHP20ActiveMatter',
            value: 0.8,
        },
        {
            name: 'sulfopon101ActiveMatter',
            value: 0.3,
        },
        {
            name: 'lutensolXP80ActiveMatter',
            value: 1,
        },
    ]



    useEffect(() => {
        setTotalActiveMatter(data.filter(element => { return !isNaN(element.activeMatterValue) }).reduce((r, a: any) => { return r + a.activeMatterValue; }, 0));
    });
    // useEffect(() => {
    //     var qwdasd = kalanDeger;

    //     setTotalValue(data.filter(element => { return element.value !== undefined }).reduce((r, a: any) => { return r + a.value; }, 0));
    //     setKalanDeger(1 - totalValue);
    //     // setKalanDeger(1 - totalValue);
    //     // if (totalValue >= 1) {
    //     //     setIsMaxed(true);
    //     // }
    // }, [data])


    // const reduced = data.length ? data.reduce((r, a: any) => { return r + a.value; }, 0) : 0;

    // const test = data.filter(element => { return element.value !== undefined }).reduce((r, a: any) => { return r + a.value; }, 0);



    const config = {
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        innerRadius: 0.6,
        label: {
            type: 'inner',
            offset: '-50%',
            content: '{value}',
            style: {
                textAlign: 'center',
                fontSize: 14,
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
        statistic: {
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'inherit',
                    textOverflow: 'ellipsis'
                },
                customHtml: () => {
                    return data.filter(element => { return element.value !== undefined }).reduce((r, a: any) => { return r + a.value; }, 0).toFixed(2);
                }
            }
        },
    };

    const onFormChange = () => {
        if (hdw !== undefined) {
            hdw.name = form.getFieldValue('name');
            hdw.description = form.getFieldValue('description');
        }
    };

    // const resetForm = () => {
    //     form.resetFields;
    // };



    if (loadingInitial) return <LoadingComponent content="Loading hdw..." />;
    if (id) {
        if (!hdw) return <LoadingComponent content="Loading modal..." />
    }

    return (
        <>
            <Form
                className="ui form"
                onFinish={handleFormSubmit}
                layout="horizontal"
                labelCol={{ span: 16 }}
                wrapperCol={{ span: 24 }}
                form={form}
                onFieldsChange={onFormChange}
                initialValues={hdw}
                // disabled={isView}
                onReset={() => form.resetFields}
            >

                <Card>
                    <Card.Grid style={{ width: '62.5%', textAlign: 'center' }}>
                        {/* <Row>
                            <Col span={12}>
                                <Form.Item >
                                    <span style={{ fontWeight: "bold" }}>
                                        Name:
                                    </span>
                                    &nbsp;
                                    <span>
                                        {selectedHdw?.name}
                                    </span>
                                </Form.Item>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col span={9}>
                                <Form.Item >
                                    Ingredients
                                </Form.Item>
                            </Col>
                            <Col span={9}>
                                <Form.Item>
                                    Concentration
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item>
                                    Active Matter %
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item>
                                    Relative Active Matter %
                                </Form.Item>
                            </Col>
                            {/* <Col span={3}>
                                <Form.Item>
                                    Input Price €/kg
                                </Form.Item>
                            </Col> */}
                        </Row>
                        <MyTextInputAnt newHdw={hdw} dataArray={hdw?.glucopon600CSUP} placeholder="Glucopon® 600 CSUP" subname="C10C16-Alkyl polyglucoside" name="glucopon600CSUP" tooltip="Active matter: 51% &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Benefits: Excellent cleaning and foam boosting" viewState={isView} activeMatters={activeMatters} totalActiveMatter={totalActiveMatter} activeMatterRatio={0.51} setTestNumber={setTestNumber} />
                        <MyTextInputAnt newHdw={hdw} dataArray={hdw?.maranilPasteA55} placeholder='Maranil® DBS' subname="" name='maranilPasteA55' tooltip="Active matter: 55%" viewState={isView} activeMatters={activeMatters} totalActiveMatter={totalActiveMatter} activeMatterRatio={0.55} setTestNumber={setTestNumber} />
                        <MyTextInputAnt newHdw={hdw} dataArray={hdw?.texaponN70} placeholder='Texapon® N 70' subname="C12C14-Fatty alcohol ethersulfate (2 EO), sodium salt" name='texaponN70' tooltip="Active matter: 70% &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Benefits: Good cleaning and foaming" viewState={isView} activeMatters={activeMatters} totalActiveMatter={totalActiveMatter} activeMatterRatio={0.70} setTestNumber={setTestNumber} />
                        <MyTextInputAnt newHdw={hdw} dataArray={hdw?.dehytonPK45} placeholder='Dehyton® PK 45' subname="Cocoamidopropyl betaine" name='dehytonPK45' tooltip="Active matter: 45% &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Benefits: Basic cleaning and foam" viewState={isView} activeMatters={activeMatters} totalActiveMatter={totalActiveMatter} activeMatterRatio={0.45} setTestNumber={setTestNumber} />
                        <MyTextInputAnt newHdw={hdw} dataArray={hdw?.dehytonPL} placeholder='Dehyton® PL' subname="Lauramine oxide" name='dehytonPL' tooltip="Active matter: 30% &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Benefits: Good cleaning and foam" viewState={isView} activeMatters={activeMatters} totalActiveMatter={totalActiveMatter} activeMatterRatio={0.3} setTestNumber={setTestNumber} />
                        <MyTextInputAnt newHdw={hdw} dataArray={hdw?.sokalanHP20} placeholder='Sokalan® HP 20' subname="Multifunctional polyethyleneimine" name='sokalanHP20' tooltip="Active matter: 80% &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Benefits: Foam stabilisation" viewState={isView} activeMatters={activeMatters} totalActiveMatter={totalActiveMatter} activeMatterRatio={0.8} setTestNumber={setTestNumber} />
                        <MyTextInputAnt newHdw={hdw} dataArray={hdw?.sulfopon101} placeholder='SULFOPON® 101 UP' subname="" name='sulfopon101' tooltip="Active matter: 30%" viewState={isView} activeMatters={activeMatters} totalActiveMatter={totalActiveMatter} activeMatterRatio={0.3} setTestNumber={setTestNumber} />
                        <MyTextInputAnt newHdw={hdw} dataArray={hdw?.lutensolXP80} placeholder='Lutensol® XP 80' subname="" name='lutensolXP80' tooltip="Active matter: 100%" viewState={isView} activeMatters={activeMatters} totalActiveMatter={totalActiveMatter} activeMatterRatio={1} setTestNumber={setTestNumber} />
                        <Row>
                            <Col span={9} style={{ textAlign: 'right' }}>
                                <Row >
                                    <Col span={16}>
                                        <span style={{ fontWeight: "bold" }}>Total </span>
                                        {/* <Tooltip title="tooltip test" >
                                            <QuestionCircleOutlined />
                                        </Tooltip> */}

                                    </Col>
                                    <Col span={8}>
                                        <Form.Item >
                                            <InputNumber
                                                disabled={true}
                                                bordered={false}
                                                step={0.01}
                                                value={data.filter(element => { return element.value !== undefined }).reduce((r, a: any) => { return r + a.value; }, 0).toFixed(2)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={9}>
                                {/* empty area */}
                            </Col>
                            <Col span={3}>
                                <Form.Item>
                                    <InputNumber
                                        disabled={true}
                                        bordered={false}
                                        step={0.01}
                                        // value={activeMatters.filter(element => { return element.value !== undefined }).reduce((r, a: any) => { return r + a.value; }, 0)}
                                        value={data.filter(element => { return !isNaN(element.activeMatterValue) }).reduce((r, a: any) => { return r + a.activeMatterValue; }, 0).toFixed(2)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item>
                                    <InputNumber
                                        disabled={true}
                                        bordered={false}
                                        step={0.01}
                                        value={1}
                                    />
                                </Form.Item>
                            </Col>
                            {/* <Col span={3}>
                                <Form.Item>
                                    <InputNumber
                                        disabled={true}
                                        bordered={false}
                                        step={0.01}
                                    // value={totalValue}
                                    />
                                </Form.Item>
                            </Col> */}
                            <Button type="primary" htmlType="submit" loading={loading} disabled={sendButtonDisable} >Send</Button>
                            {/* <Button type="primary" htmlType="submit" loading={loading} disabled={!activeMatterAttentionVisible} >Send</Button> */}
                            {/* <Button htmlType="reset" onClick={() => setHdw(new Hdw())} >Clear</Button> */}
                            {/* <Button htmlType="reset" disabled={false} >Clear</Button> */}
                            {/* <Button disabled={false} onClick={() => window.location.reload()}>Clear</Button> */}
                            <Button htmlType="reset" disabled={false} onClick={() => { history.push('/hdwAiRequest'); window.location.reload(); }}>Clear</Button>

                        </Row>
                        {/* activeMatterAttentionVisible */}
                        <Tag
                            color="red"
                            hidden={activeMatterAttentionVisible}
                            icon={<ExclamationCircleOutlined />}
                            style={{ fontSize: '150%' }}
                        >
                            Total of active matter percentage must be between %12 - %16. Now it is %{data.filter(element => { return !isNaN(element.activeMatterValue) }).reduce((r, a: any) => { return r + a.activeMatterValue*100; }, 0).toFixed(0)}
                        </Tag>
                    </Card.Grid>
                    <Card.Grid style={{ width: '37.5%' }}>
                        <span style={{ fontWeight: "bold" }} >Result</span>
                        <HdwFormResults />
                        <HdwFormModal hdw={hdw!} setHdw={setHdw} form={form} />
                        <Form.Item style={{ textAlign: 'center' }}>
                            Total Concentration
                        </Form.Item>
                        <Pie {...config} legend={{ layout: 'horizontal', position: 'bottom', flipPage: false }} padding='auto' />
                    </Card.Grid>
                    {/* <Card.Grid style={{ width: '45%' }}>
                        <Row>
                            <Col span={12} >
                                <Form.Item style={{ textAlign: 'center' }}>
                                    Total Concentration
                                </Form.Item>
                                <Pie {...config} legend={{ layout: 'horizontal', position: 'bottom', flipPage: false }} padding='auto' />
                            </Col>
                            <Col span={12} style={{ justifyContent: 'center' }}>
                                <span>Result</span>
                                <HdwFormResults />
                                <HdwFormModal hdw={hdw!} setHdw={setHdw} />
                            </Col>
                        </Row>
                    </Card.Grid> */}
                </Card>
            </Form>
            <HdwFormList selectedHdw={selectedHdw!} setViewState={setIsView} form={form} setHdw={setHdw} hdw={hdw} />
        </>
    )
})