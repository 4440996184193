import { User } from "./user";

export interface Profile {
    username: string;
    displayName: string;
    image?: string
    bio?: string;
    followersCount: number;
    followingCount: number;
    following: boolean;
}

export class Profile implements Profile {
    constructor(user: User) {
        this.username = user.username;
        this.displayName = user.displayName;
    }
}

