import { Profile } from "./profile";

export interface Hdw {
    id: string;
    maranilPasteA55: number;
    texaponN70: number;
    dehytonPK45: number;
    dehytonPL: number;
    glucopon600CSUP: number;
    sokalanHP20: number;
    sulfopon101: number;
    lutensolXP80: number;
    platesIKW: number;
    createDate: Date;
    ownerUsername: string;
    isSubscriber: boolean;
    isOwner: boolean;
    owner?: Profile;
    subscribers: Profile[];
    isFavorite: boolean;
    name?: string;
    description?: string;
}

export class Hdw implements Hdw {
    constructor(init?: HdwFormValues) {
        Object.assign(this, init);
    }
}

export class HdwFormValues {
    id?: string = undefined;
    maranilPasteA55: number = 0;
    texaponN70: number = 0;
    dehytonPK45: number = 0;
    dehytonPL: number = 0;
    glucopon600CSUP: number = 0;
    sokalanHP20: number = 0;
    sulfopon101: number = 0;
    lutensolXP80: number = 0;
    platesIKW: number = 0;
    createDate: Date | null = null;
    name?: string = "";
    description?: string = "";

    constructor(hdw?: HdwFormValues) {
        if (hdw) {
            this.id = hdw.id;
            this.dehytonPK45 = hdw.dehytonPK45;
            this.dehytonPL = hdw.dehytonPL;
            this.glucopon600CSUP = hdw.glucopon600CSUP;
            this.lutensolXP80 = hdw.lutensolXP80;
            this.maranilPasteA55 = hdw.maranilPasteA55;
            this.sokalanHP20 = hdw.sokalanHP20;
            this.sulfopon101 = hdw.sulfopon101;
            this.texaponN70 = hdw.texaponN70;
            this.platesIKW = hdw.platesIKW;
            this.createDate = hdw.createDate;
            this.name = hdw.name;
            this.description = hdw.description;
        }
    }
}