import axios, { AxiosError, AxiosResponse } from "axios";
import { Hdw, HdwFormValues } from "../models/hdw";
import { User, UserFormValues } from "../models/user";
import { store } from "../stores/store";
import { history } from '../..';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

//axios.defaults.baseURL = 'http://localhost:5000/api';
//axios.defaults.baseURL = 'http://localhost:8080/api';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if (token) config.headers!.Authorization = `Bearer ${token}`;
    return config;
})

axios.interceptors.response.use(async response => {
    return response;
}, (error: AxiosError) => {
    const { data, status, config } = error.response!;
    switch (status) {
        case 400:
            if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
                history.push('/not-found');
            }
            if (data.errors) {
                const modalStateErrors = [];
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modalStateErrors.push(data.errors[key])
                    }
                }
                throw modalStateErrors.flat();
            } else {
                toast.error(data);
            }
            break;
        case 401:
            history.push('/');
            toast.error('you are unauthorised');
            break;
        case 404:
            history.push('/not-found');
            break;
        case 500:
            store.commonStore.setServerError(data);
            history.push('/server-error');
            break;
    }
    return Promise.reject(error);
})

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string) => axios.get<T>(url).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
}

const HdwRequests = {
    list: () => requests.get<Hdw[]>('/hdwRequests'),
    details: (id: string) => requests.get<Hdw>(`/hdwRequests/${id}`),
    create: (hdw: HdwFormValues) => requests.post<void>('/hdwRequests', hdw),
    hdwAiRequest: (hdw: HdwFormValues) => requests.post<Hdw>(`/hdwRequests/${hdw.id}`, hdw),
    update: (hdw: HdwFormValues) => requests.put<void>(`/hdwRequests/${hdw.id}`, hdw),
    delete: (id: string) => requests.del<void>(`/hdwRequests/${id}`),
    subscribe: (id: string) => requests.post<void>(`/hdwRequests/${id}/subscribe`, {})
}

const Account = {
    current: () => requests.get<User>('/Account'),
    login: (user: UserFormValues) => requests.post<User>('/account/login', user),
    register: (user: UserFormValues) => requests.post<User>('/account/register', user)
}

const agent = {
    HdwRequests,
    Account
}

export default agent;