import Menu from 'antd/lib/menu';
import { observer } from 'mobx-react-lite';
import { useStore } from '../stores/store';
import { ExperimentOutlined, PoweroffOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Layout } from 'antd';
import Link from 'antd/lib/typography/Link';
import { useHistory } from 'react-router-dom';


export default observer(function NavBar() {
    const { userStore: { user, logout } } = useStore();
    const [collapsed, setCollapsed] = useState(false);
    const { Sider } = Layout;
    const history = useHistory();


    return (
        <>
            <Sider collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
                <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                    {/* <Menu.Item icon={<HomeOutlined />} >
                        <Link href='/' style={{ textDecoration: 'none' }}>
                            IctAI
                        </Link>
                    </Menu.Item> */}
                    <Menu.Item key={"hdwRequests"} icon={<ExperimentOutlined />} onClick={() => history.push(`/hdwRequests`)}>
                        Dashboard
                    </Menu.Item>
                    <Menu.Item key={"hdwAiRequest"} icon={<ExperimentOutlined />}>
                        <Link href='/hdwAiRequest' style={{ textDecoration: 'none' }}>
                            Create Form
                        </Link>
                    </Menu.Item>
                    <Menu.Item key={"logout"} onClick={logout} icon={<PoweroffOutlined />}>
                        <a>Logout</a>
                    </Menu.Item>
                    {/* <SubMenu title={user?.displayName} icon={<UserOutlined />}>
                        <Menu.Item icon={<ProfileOutlined />} onClick={() => history.push(`/profile/${user?.username}`)}>
                            <a>My Profile</a>
                        </Menu.Item>
                        <Menu.Item onClick={logout} icon={<PoweroffOutlined />}>
                            <a>My Logout</a>
                        </Menu.Item>
                    </SubMenu> */}

                </Menu>
            </Sider>
        </>
    )
})