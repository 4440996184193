import { Button, Form, Input, message, Modal, Popconfirm } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Hdw, HdwFormValues } from "../../../app/models/hdw";
import { useStore } from "../../../app/stores/store";

interface Props {
    setHdw: any,
    hdw: Hdw,
    form:any,
}

export default observer(function HdwFormModal({ hdw, setHdw,form }: Props) {
    const { hdwStore } = useStore();
    const { updateFavorite } = hdwStore;
    const [visible, setVisible] = useState(false);

    const onSaveModel = () => {
        setVisible(false);
        updateFavorite(hdw).then(() => setHdw(new HdwFormValues()));
        form.resetFields();
    }
    const cancel = (e: React.MouseEvent<HTMLElement>) => {
        console.log(e);
        message.error('Click on No');
    };

    return (
        <>
            <Modal
                title='Add to favorites'
                centered
                visible={visible}
                onOk={onSaveModel}
                okText="Add"
                onCancel={() => setVisible(false)}
                cancelText="Cancel"
                width={1000}
            >
                <Input.Group size="large" >
                    <Form.Item name="name" >
                        <TextArea rows={1} autoSize placeholder="Name" allowClear />
                    </Form.Item>
                    <Form.Item name="description">
                        <TextArea rows={4} placeholder="Description" allowClear />
                    </Form.Item>
                </Input.Group>
            </Modal>
            <p>You can save this formulation as your favorite! You can list your favorite formulations anytime!</p>
            {(hdw && hdw.isFavorite) ?
                <Popconfirm
                    title="Are you sure to delete this modal?"
                    onConfirm={onSaveModel}
                    onCancel={() => cancel}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="primary" htmlType="reset">
                        remove fav
                    </Button>
                </Popconfirm>
                :
                <Button type="primary" onClick={() => setVisible(true)} disabled={(typeof hdw !== 'undefined') ? false : true}>
                    Add to favorites
                </Button>
            }

        </>
    )
})