import { Form, Input, InputNumber } from "antd";
import { observer } from "mobx-react-lite";

export default observer(function HdwFormResults() {
    return (
        <>
            <Form.Item name="platesIKW">
                <InputNumber disabled={true} precision={2} />
            </Form.Item>
        </>
    )
})