import {
    JsonHubProtocol,
    HubConnectionState,
    HubConnectionBuilder,
    LogLevel
} from '@microsoft/signalr';
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Container, Header, Segment, Image, Button } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import LoginForm from "../users/LoginForm";
import RegisterForm from "../users/RegisterForm";

export default observer(function HomePage() {
    const { userStore, modalStore } = useStore();

    return (
        //HomePage
        <Segment inverted textAlign="center" vertical className="masthead">
            <Container text>
                <Header as='h1' inverted>
                    <Image size="massive" src='/assets/logo.png' alt='logo' style={{ marginBottom: 12 }} />
                    Ai Based Experiment Predictor
                </Header>
                {userStore.isLoggedIn ? (
                    <>
                        <Header as='h2' inverted content='Welcome to Ai Based Experiment Predictor' />
                        <Button as={Link} to='/hdwRequests' size="huge" inverted>
                            Go to Ai Lab Experiment Predictor!
                        </Button>
                    </>

                ) : (
                    <>
                        <a href='https://federation.basf.com/nidp/saml2/idpsend?PID=https://ailabs-ict.basf.com/'>
                            <Button size="huge" inverted>
                                Login!!
                            </Button>
                        </a>

                        {/* <Button onClick={() => modalStore.openModal(<LoginForm />)} size="huge" inverted>
                            Login!(DEV)
                        </Button>  */}
                        {/* <Button onClick={() => modalStore.openModal(<RegisterForm />)} size="huge" inverted>
                            Register!
                        </Button> */}
                    </>
                )}
            </Container>
        </Segment>
    )
})