import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from "../../../app/stores/store";
import HdwDetailedHeader from "./HdwDetailedHeader";
import HdwDetailedInfo from "./HdwDetailedInfo";
import HdwDetailedSidebar from "./HdwDetailedSidebar";


export default observer(function HdwDetails() {

    const { hdwStore } = useStore();
    const { selectedHdw: hdw, loadHdwRequest, loadingInitial } = hdwStore;
    const {id} = useParams<{id:string}>();

    useEffect(() => {
        if(id) loadHdwRequest(id);
    },[id,loadHdwRequest]);

    if (loadingInitial || !hdw) return <LoadingComponent />;

    return (
       <Grid>
           <Grid.Column width={10}>
                <HdwDetailedHeader hdw={hdw}/>
                <HdwDetailedInfo hdw={hdw} />
                {/* <HdwDetailedChat/> */}
           </Grid.Column>
           <Grid.Column width={6} >
                <HdwDetailedSidebar hdw={hdw}/>
           </Grid.Column>
       </Grid>
    )
})