import { Card, Col, Form, InputNumber, Row, Slider, Tooltip } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { QuestionCircleOutlined } from '@ant-design/icons';

interface Props {
    placeholder: string;
    name: string;
    type?: string;
    label?: string;
    viewState: any;
    // totalValue: any;
    activeMatters: {
        name: string;
        value: number;
    }[];
    totalActiveMatter: any;
    activeMatterRatio: number;
    setTestNumber: any;
    tooltip: string;
    subname: string;
    dataArray: any;
    newHdw: any;
}

export default observer(function MyTextInputAnt(props: Props) {

    const [inputValue, setInputValue] = useState(0);
    const [activeMatterValue, setActiveMatterValue] = useState(0);

    const onChange = (value: number) => {
        if (isNaN(value)) {
            return;
        }
        setInputValue(value);
        setActiveMatterValue(value * props.activeMatterRatio);
        props.setTestNumber(value);
    };

    const resetForm = () => {
        setActiveMatterValue(0)
    };


    useEffect(() => {
        setActiveMatterValue(props.dataArray * props.activeMatterRatio)
    }, [props.newHdw]);

    return (
        <>

            <Row>
                <Col span={9} style={{ textAlign: 'right' }}>
                    <Row >
                        <Col span={16}>
                            <span>{props.placeholder} </span>
                            <Tooltip title={props.tooltip} >
                                <QuestionCircleOutlined />
                            </Tooltip>
                            <br />
                            <span style={{ fontSize: "12px", color: "GrayText" }}>{props.subname} </span>

                        </Col>
                        <Col span={8}>
                            <Form.Item name={props.name} onReset={resetForm}>
                                <InputNumber
                                    disabled={props.viewState}
                                    defaultValue={0}
                                    min={0}
                                    max={1}
                                    bordered={false}
                                    step={0.01}
                                    value={inputValue}
                                    onChange={onChange}
                                    controls={false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={9}>
                    <Form.Item name={props.name} >
                        <Slider
                            disabled={props.viewState}
                            min={0}
                            max={1}
                            style={{ marginLeft: 32 }}
                            step={0.01}
                            onChange={onChange}
                            value={typeof inputValue === 'number' ? inputValue : 0}
                        />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item>
                        <InputNumber
                            disabled={true}
                            // defaultValue={activeMatters.find(data => data.name === props.name + "ActiveMatter")?.value }
                            bordered={false}
                            step={0.01}
                            value={parseFloat(activeMatterValue.toFixed(2))}
                            controls={false}
                        />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item >
                        <InputNumber
                            disabled={true}
                            bordered={false}
                            value={parseFloat((activeMatterValue / props.totalActiveMatter).toFixed(2))}
                            onChange={onChange}
                            controls={false}
                        />
                    </Form.Item>
                </Col>
                {/* <Col span={3}>
                    <Form.Item name={props.name} required tooltip={props.name}>
                        <InputNumber
                            disabled={true}
                            defaultValue={0}
                            min={0}
                            max={1}
                            bordered={false}
                            step={0.01}
                            value={inputValue}
                            onChange={onChange}
                            controls={false}
                        />
                    </Form.Item>
                </Col> */}
            </Row>
        </>
    )
})